<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn
    animate__faster"
    leave-active-class="animate__animated
    animate__fadeOut animate__faster"
    mode="out-in"
  >
    <search-bar v-if="showSearch" />
  </transition>
  <main-header />

  <router-view v-slot="{ Component, route }">
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
      mode="out-in"
    >
      <component :is="Component" :key="route.path" />
    </transition>
  </router-view>
</template>

<script setup>
import { onMounted, watch, ref, onBeforeUnmount } from "vue";
import { RouterView } from "vue-router";
import MainHeader from "./components/MainHeader.vue";
import SearchBar from "./components/SearchBar/SearchBar.vue";
import { showSearch } from "./services/helpers";
import { usePreferencesStore } from "./stores";
import { useHead } from "@vueuse/head";

import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const preferencesStore = usePreferencesStore();

const { locale } = useI18n({ useScope: "global" });

// Reaktive Referenz für das Marketing-Consent
const consentMarketing = ref(false);

// Funktion zum Laden von GTM
const loadGtm = () => {
  if (
    consentMarketing.value &&
    !document.querySelector(
      'script[src*="googletagmanager.com/gtm.js?id=GTM-M55TJQVS"]'
    )
  ) {
    useHead({
      script: [
        {
          children: `
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-M55TJQVS');
          `,
          type: "text/javascript",
        },
      ],
    });
  }
};

// Event-Handler für Consent-Änderungen
const handleConsentDeclaration = () => {
  if (
    window.Cookiebot &&
    window.Cookiebot.consent &&
    window.Cookiebot.consent.marketing
  ) {
    consentMarketing.value = true;
    loadGtm();
  }
};

onMounted(async () => {
  //erst wenn Router fertig ist, kann Sprache gesetzt werden, sodass Cookiebot die richtige Sprache anzeigt.

  useHead({
    htmlAttrs: {
      lang: () => preferencesStore.language,
    },
    script: [
      {
        src: "https://consent.cookiebot.com/uc.js",
        defer: true,
        "data-cbid": "9d3a31c7-ad6e-4c91-ba56-4abe0a7dbb0c",
        "data-blockingmode": "auto",
        type: "text/javascript",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: () => "https://sachsenkabel.de" + route.path,
      },
    ],
  });

  await router.isReady();
  preferencesStore.updateGivenLanguages();
  preferencesStore.updateButtonTypes();

  // Initiale Consent-Prüfung
  if (
    window.Cookiebot &&
    window.Cookiebot.consent &&
    window.Cookiebot.consent.marketing
  ) {
    consentMarketing.value = true;
    loadGtm();
  }

  // Event-Listener hinzufügen
  if (window.Cookiebot) {
    window.addEventListener(
      "CookieConsentDeclaration",
      handleConsentDeclaration
    );
  } else {
    // Falls Cookiebot noch nicht geladen ist, prüfen wir regelmäßig
    const checkCookiebotInterval = setInterval(() => {
      if (window.Cookiebot) {
        window.addEventListener(
          "CookieConsentDeclaration",
          handleConsentDeclaration
        );
        // Initiale Consent-Prüfung nach Laden des Skripts
        if (window.Cookiebot.consent && window.Cookiebot.consent.marketing) {
          consentMarketing.value = true;
          loadGtm();
        }
        clearInterval(checkCookiebotInterval);
      }
    }, 1000); // Prüfe alle 1 Sekunde
  }
});

onBeforeUnmount(() => {
  window.removeEventListener(
    "CookieConsentDeclaration",
    handleConsentDeclaration
  );
});
</script>
